/* Add these new styles to your existing CSS */

.addButtonContainer {
  position: relative;
  display: inline-block;
}

.addOptions {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px;
  margin-top: 4px;
}

.addOptions button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
  font-size: 14px;
  transition: background-color 0.2s;
}

.addOptions button:hover {
  background-color: #f5f5f5;
}

.addOptions button:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.addOptions button:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* Existing styles */

.container {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.headerRight {
  display: flex;
  gap: 10px;
  align-items: center;
}

.sectionTitle {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.viewButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #e1e1e1;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.viewButton:hover {
  background: #f5f5f5;
}

.viewButton.active {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.addButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.addButton:hover {
  background: #218838;
}

.buttonText {
  font-size: 14px;
}

.contentSection {
  flex: 1;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.searchBox {
  flex: 1;
  min-width: 200px;
}

.searchInput {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 14px;
}

.filterSelect {
  padding: 8px 12px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 14px;
  min-width: 150px;
}

.bulkActions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.bulkActionButton {
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  background: #007bff;
  color: white;
  transition: background-color 0.2s;
}

.bulkActionButton:hover {
  background: #0056b3;
}

.bulkActionButton:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.deleteButton {
  background: #dc3545;
}

.deleteButton:hover {
  background: #c82333;
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
}

.table th {
  background: #f8f9fa;
  font-weight: 600;
}

.sortable {
  cursor: pointer;
}

.sortable:hover {
  background: #e9ecef;
}

.tableRow:hover {
  background: #f8f9fa;
}

.activeStatus,
.inactiveStatus {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.activeStatus {
  background: #d4edda;
  color: #155724;
}

.inactiveStatus {
  background: #f8d7da;
  color: #721c24;
}

.editButton {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.editButton:hover {
  background: #0056b3;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
}

.error {
  color: #dc3545;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #dc3545;
  border-radius: 4px;
  background: #f8d7da;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.closeButton:hover {
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}

.formField {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.formField label {
  font-size: 14px;
  color: #666;
}

.formField input,
.formField select {
  padding: 8px 12px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 14px;
}

.submitButton {
  padding: 10px 20px;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background: #218838;
}

.success {
  color: #155724;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #28a745;
  border-radius: 4px;
  background: #d4edda;
}

.placeholderCard {
  text-align: center;
  padding: 40px;
  background: #f8f9fa;
  border-radius: 8px;
  border: 2px dashed #dee2e6;
}

.placeholderIcon {
  font-size: 48px;
  margin-bottom: 16px;
}

.placeholderCard h3 {
  margin: 0 0 8px;
  color: #333;
}

.placeholderCard p {
  margin: 0;
  color: #666;
}
