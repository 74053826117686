/* Variables */
:root {
  --color-primary: #007AFF;
  --color-primary-dark: #0066CC;
  --color-text: #1C1C1E;
  --color-secondary: #8E8E93;
  --color-border: #E1E1E1;
  --color-background: #F8F8F8;
  --color-background-hover: #F2F2F7;
  --color-success: #34C759;
  --color-error: #FF3B30;
  --color-admin: #FF9500;
  --color-delete: #FF3B30;
  --border-radius-sm: 12px;
  --border-radius-lg: 16px;
  --border-radius-pill: 20px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 12px;
  --spacing-lg: 16px;
  --spacing-xl: 20px;
  --spacing-xxl: 24px;
  --spacing-xxxl: 32px;
  --font-size-xs: 12px;
  --font-size-sm: 13px;
  --font-size-md: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 20px;
  --font-weight-semibold: 600;
  --transition-default: all 0.2s ease;
  --shadow-default: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
}

/* Layout */
.container {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.headerRight {
  z-index: 1;
  display: flex;
  gap: var(--spacing-md);
}

/* Activity Section */
.activitySection {
  flex: 1;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

/* Buttons */
.addButton, .viewButton {
  background: var(--color-primary);
  color: white;
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-pill);
  border: none;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: var(--transition-default);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.addButton:hover, .viewButton:hover {
  background: var(--color-primary-dark);
}

/* Button Text */
.buttonText {
  display: inline;
}

/* Action Buttons */
.userActions {
  display: flex;
  gap: var(--spacing-sm);
  margin-right: var(--spacing-md);
}

.actionButton {
  background: none;
  border: none;
  padding: var(--spacing-xs);
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-default);
  color: var(--color-secondary);
}

.actionButton:hover {
  background: var(--color-background);
}

.changePasswordButton:hover {
  color: var(--color-primary);
}

.deleteButton:hover {
  color: var(--color-delete);
}

/* Modal */
.modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease;
}

.modalContent {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-xxl);
  width: 90%;
  max-width: 400px;
  box-shadow: var(--shadow-default);
  border: 1px solid rgba(255, 255, 255, 0.18);
  animation: slideUp 0.3s ease;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xl);
}

.closeButton {
  background: none;
  border: none;
  color: var(--color-secondary);
  cursor: pointer;
  font-size: var(--spacing-xxl);
  padding: var(--spacing-xs);
  width: var(--spacing-xxxl);
  height: var(--spacing-xxxl);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: var(--transition-default);
}

.closeButton:hover {
  background-color: rgba(142, 142, 147, 0.1);
}

/* Forms */
.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.form input, .form select, .filterBar select, .filterBar input {
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-border);
  background: var(--color-background);
  font-size: var(--font-size-md);
  transition: var(--transition-default);
  appearance: none;
}

.form input:focus, .form select:focus {
  outline: none;
  border-color: var(--color-primary);
  background: white;
  box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.1);
}

.form select {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right var(--spacing-md) center;
  background-size: var(--spacing-lg);
  padding-right: 40px;
}

.form button[type="submit"] {
  background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
  color: white;
  padding: 14px;
  border-radius: var(--border-radius-sm);
  border: none;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: var(--transition-default);
  box-shadow: 0 2px 8px rgba(0, 122, 255, 0.2);
}

/* Status Messages */
.error, .success {
  padding: var(--spacing-md);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-md);
}

.error {
  color: var(--color-error);
  background: rgba(255, 59, 48, 0.1);
}

.success {
  color: var(--color-success);
  background: rgba(52, 199, 89, 0.1);
}

/* User List */
.userList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  max-height: 400px;
  overflow-y: auto;
}

.userListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  background: var(--color-background);
  border-radius: var(--border-radius-sm);
  transition: var(--transition-default);
}

.userListItem:hover {
  background: var(--color-background-hover);
}

.userListInfo {
  flex: 1;
}

.userName {
  font-weight: var(--font-weight-semibold);
  color: var(--color-text);
  font-size: var(--font-size-md);
  margin-bottom: var(--spacing-xs);
}

.userEmail {
  color: var(--color-secondary);
  font-size: var(--font-size-sm);
}

.filterBar {
  display: flex;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-xl);
  flex-wrap: wrap;
}

.filterBar select, .filterBar input {
  min-width: 150px;
}

.activityList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

/* Typography */
.sectionTitle {
  font-size: 24px;
  color: #333;
  margin: 0;
}

/* Roles */
.role {
  display: inline-block;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--border-radius-pill);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
}

.roleAdmin {
  background: rgba(255, 149, 0, 0.1);
  color: var(--color-admin);
}

.roleUser {
  background: rgba(0, 122, 255, 0.1);
  color: var(--color-primary);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .buttonText {
    display: none;
  }

  .addButton, .viewButton {
    padding: var(--spacing-sm);
  }

  .headerRight {
    gap: var(--spacing-sm);
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
