:root {
  --primary-color: #007AFF;
  --background-gradient-start: #F2F2F7;
  --background-gradient-end: #E5E5EA;
  --text-color: #000000;
  --secondary-text: #8E8E93;
}

.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, var(--background-gradient-start), var(--background-gradient-end));
}

.form {
  background: rgba(255, 255, 255, 0.95);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 
    0 10px 25px rgba(0, 0, 0, 0.05),
    0 20px 48px rgba(0, 0, 0, 0.05),
    0 1px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  backdrop-filter: blur(10px);
}

.logoContainer {
  text-align: center;
  margin-bottom: 1rem;
}

.logo {
  width: 180px;
  height: auto;
  margin-bottom: 1rem;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 0.5rem 0;
  color: var(--text-color);
  background: linear-gradient(135deg, #1a1a1a, #4a4a4a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form input {
  padding: 1rem;
  border: 1.5px solid #e1e1e1;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.9);
}

.form input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.1);
  transform: translateY(-1px);
}

.form button {
  background: linear-gradient(135deg, #007AFF, #0055FF);
  color: white;
  border: none;
  padding: 1rem;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
}

.form button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.2);
}

.form button:active {
  transform: translateY(0);
}

.error {
  color: #dc2626;
  background: rgba(254, 226, 226, 0.9);
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 0.5rem;
  border: 1px solid rgba(220, 38, 38, 0.1);
  font-size: 0.9rem;
}

.poweredBy {
  text-align: center;
  margin-top: 1rem;
  color: var(--secondary-text);
  font-size: 0.9rem;
}

.poweredBy a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.poweredBy a:hover {
  color: #0055FF;
}
