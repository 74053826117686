.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  height: 72px;
}

.headerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 200px;
}

.backButtonContainer {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButtonPlaceholder {
  width: 36px;
  height: 36px;
}

.greeting {
  color: #1C1C1E;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.logoWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 200px;
}

.logo {
  height: 48px;
  width: auto;
  object-fit: contain;
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 200px;
  justify-content: flex-end;
}

.iconButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.iconButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.buttonIcon {
  width: 20px;
  height: 20px;
  fill: #1C1C1E;
}

.dropdownContainer {
  position: relative;
}

.dropdownToggle {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.dropdownToggle:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 8px;
  min-width: 180px;
  animation: slideDown 0.2s ease;
}

.dropdownMenu button {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
  color: #1C1C1E;
  font-size: 14px;
  text-align: left;
  transition: background-color 0.2s ease;
}

.dropdownMenu button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.menuIcon {
  width: 18px;
  height: 18px;
  fill: #1C1C1E;
}

.main {
  flex: 1;
  padding: 20px;
  background-color: #F2F2F7;
}

@media (max-width: 768px) {
  .headerContent {
    padding: 12px 10px;
  }

  .leftSection {
    width: 160px;
  }

  .greeting {
    font-size: 12px;
  }

  .logoWrapper {
    max-width: 120px;
  }

  .logo {
    height: 32px;
  }

  .actionButtons {
    width: 160px;
  }

  .main {
    padding: 7px 10px;
  }

  .dropdownMenu {
    right: -10px;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
