/* Variables */
:root {
  --color-primary: #007AFF;
  --color-primary-dark: #0066CC;
  --color-text: #1C1C1E;
  --color-secondary: #8E8E93;
  --color-border: #E1E1E1;
  --color-background: #F8F8F8;
  --color-background-hover: #F2F2F7;
  --border-radius-sm: 12px;
  --border-radius-lg: 16px;
  --border-radius-pill: 20px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 12px;
  --spacing-lg: 16px;
  --spacing-xl: 20px;
  --spacing-xxl: 24px;
  --spacing-xxxl: 32px;
  --font-size-xs: 12px;
  --font-size-sm: 13px;
  --font-size-md: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 20px;
  --font-weight-semibold: 600;
  --transition-default: all 0.2s ease;
  --shadow-default: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
}

/* Layout */
.container {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Typography */
.sectionTitle {
  font-size: 24px;
  color: #333;
  margin: 0;
}

/* Content Section */
.contentSection {
  flex: 1;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

/* Placeholder */
.placeholderCard {
  text-align: center;
  padding: 40px;
  background: #f8f9fa;
  border-radius: 8px;
  border: 2px dashed #dee2e6;
}

.placeholderIcon {
  font-size: 48px;
  margin-bottom: 16px;
}

.placeholderCard h3 {
  margin: 0 0 8px;
  color: #333;
}

.placeholderCard p {
  margin: 0;
  color: #666;
}
